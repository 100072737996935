import { DuckSelector, objectDuckCreator } from '@atc/modular-redux';

import { srpDuckPreset } from '@/ducks/presets';

const srpActiveInteractionDuck = objectDuckCreator({
    ...srpDuckPreset,
    store: 'activeInteraction',
    initialState: {
        activeModel: '',
        clickType: '',
        compareTrimsFuelType: '',
        emailCTAContext: '',
        fuelTypeOptions: [],
        lastPlacementInteraction: '',
        ownerId: '',
        parentId: '',
        showCompareModal: false,
        showDealerDiffModal: false,
        showReportModal: false,
        showEditEmailModal: false,
        showEmailModal: false,
        showEmailCaptureModal: false,
        showEmailConfirmationModal: false,
        showShareListingModal: false,
        showTrimsModal: false,
        isTrimsTabOpen: false,
        showSpinningIndicator: false,
    },
}).extend({
    selectors: () => ({
        getActiveModel: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).activeModel),
        getClickType: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).clickType),
        getEmailCTAContext: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).emailCTAContext),
        getIsTrimsTabOpen: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).isTrimsTabOpen),
        getLastPlacementInteraction: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).lastPlacementInteraction),
        getParentId: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).parentId),
        getOwnerId: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).ownerId),
        getShowCompareModal: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).showCompareModal),
        getShowDealerDiffModal: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).showDealerDiffModal),
        getShowEmailCaptureModal: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).showEmailCaptureModal),
        getShowEmailModal: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).showEmailModal),
        getShowEditEmailModal: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).showEditEmailModal),
        getShowEmailConfirmationModal: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).showEmailConfirmationModal),
        getShowShareListingModal: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).showShareListingModal),
        getShowReportModal: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).showReportModal),
        getFuelTypeOptions: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).fuelTypeOptions),
        getCompareTrimsFuelType: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).compareTrimsFuelType),
        getShowTrimsModal: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).showTrimsModal),
        getShowSpinningIndicator: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).showSpinningIndicator),
    }),
});

export default srpActiveInteractionDuck;
